<template>
  <div>
    <div class="row mb-4">
      <div class="col">
        <h5 class="fw-bold" v-if="intake.booking_id">
          Attached to Booking #{{ intake.booking_id }}
        </h5>

        <h5 class="fw-bold" v-else>Not Attached to Booking</h5>
      </div>
      <div class="col-auto ms-auto">
        <button class="btn btn-sm btn-outline-success" @click="close">
          <i class="far fa-times"></i>
        </button>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-6">
        <h5 class="fw-bold mb-2">Juicing</h5>

        <p class="alert alert-success">{{ intake.juice_type || "n/a" }}</p>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col">
        <h5 class="fw-bold mb-2">Allergies</h5>

        <p class="alert alert-success">{{ intake.allergies || "n/a" }}</p>
      </div>

      <div class="col">
        <h5 class="fw-bold mb-2">Intolerances</h5>

        <p class="alert alert-success">{{ intake.intolerances || "n/a" }}</p>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col">
        <h5 class="fw-bold mb-2">Extra Info</h5>

        <p class="alert alert-success">{{ intake.extra_info || "n/a" }}</p>
      </div>

      <div class="col">
        <h5 class="fw-bold mb-2">Critical Info</h5>

        <p class="alert alert-success">{{ intake.critical_info || "n/a" }}</p>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col" v-if="$can('manage enquiries')">
        <h5 class="fw-bold mb-2">Management Notes</h5>

        <p class="alert alert-success">
          {{ intake.management_notes || "n/a" }}
        </p>
      </div>

      <div class="col" v-if="$can('view kitchen')">
        <h5 class="fw-bold mb-2">Kitchen Notes</h5>

        <p class="alert alert-success">{{ intake.kitchen_notes || "n/a" }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["intake"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style>
</style>