<template>
  <div class="container">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Booking ID</th>
          <th scope="col">Juicing</th>
          <th scope="col">Created</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="i in intakes" :key="i.id">
          <td @click="selectIntake(i)">{{ i.booking_id || "n/a" }}</td>
          <td @click="selectIntake(i)">{{ i.juice_type || "n/a" }}</td>
          <td @click="selectIntake(i)">{{ i.created_at | formatDate }}</td>
          <td>
            <button class="btn btn-sm btn-danger" @click="deleteIntake(i)">
              <i class="far fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      intakes: [],
    };
  },
  methods: {
    selectIntake(intake) {
      this.$emit("selected", intake);
    },
    deleteIntake(intake) {
      if (confirm("Are you sure you wish to delete this intake form?")) {
        this.$axios
          .delete(process.env.VUE_APP_API_URL + "/intake-forms/" + intake.id)
          .then(({ data }) => {
            this.fetchIntakes();
          });
      }
    },
    fetchIntakes() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.$route.params.id +
            "/intake-forms"
        )
        .then(({ data }) => {
          this.intakes = data;
        });
    },
  },
  mounted() {
    this.$EventBus.$on("client-intake-forms-added", this.fetchIntakes());
    this.fetchIntakes();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
  },
};
</script>

<style>
</style>