<template>
  <div class="pt-3">
    <div class="row mb-4">
      <div class="col my-auto">
        <h5 class="mb-0 fw-bold text-success">Files</h5>
      </div>
      <div class="col-auto ms-auto">
        <label>
          <input
            type="file"
            id="file"
            ref="file"
            class="form-control form-control-sm"
            @change="handleFileUpload()"
          />
        </label>
        <button
          class="btn btn-outline-success btn-sm ms-2"
          @click="submitFile()"
        >
          Submit
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3 mb-4" v-for="f in files" :key="f.id">
        <div
          class="card border-0 bg-light text-center shadow-sm"
        >
          <div class="card-body">
            <div
              @click="openFile(f)"
              class="cursor-pointer"
            >
              <div>
                <i class="far fa-file fa-2x text-success"></i>
              </div>
              <p class="text-success fw-bold cursor-pointer">
                {{ f.name }}
              </p>
            </div>

            <div class="my-2">
              <small>Uploaded by: {{ f.user ? f.user.name : 'Unkown' }}</small
              ><br />
              <small>{{ f.created_at | formatDate }}</small>
            </div>

            <button
              class="btn btn-sm btn-outline-danger my-2"
              @click="deleteFile(f)"
            >
              <i class="far fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      files: [],
      file: "",
    };
  },

  methods: {
    /*
        Submits the file to the server
      */
    submitFile() {
      /*
                Initialize the form data
            */
      let formData = new FormData();

      /*
                Add the form data we need to submit
            */
      formData.append("file", this.file);

      /*
          Make the request to the POST /single-file URL
        */
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.$route.params.id +
            "/files",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(({ data }) => {
          console.log(data);
          this.file = "";
          this.fetchFiles();
        });
    },

    /*
        Handles a change on the file upload
      */
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    openFile(f) {
      window.open(f.url, "_blank");
    },
    deleteFile(f) {
      if (
        confirm(
          "Are you sure you wish to delete this file, this action cannot be undone?"
        )
      ) {
        this.$axios
          .delete(
            process.env.VUE_APP_API_URL +
            "/clients/" +
            this.$route.params.id +
            "/files/" +
            f.id
          )
          .then(({ data }) => {
            if (data.color !== "danger") {
              this.files.splice(this.files.indexOf(f), 1);
            }

            this.$EventBus.$emit("alert", data);
          });
      }
    },
    fetchFiles() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.$route.params.id +
            "/files"
        )
        .then(({ data }) => {
          this.files = data;
        });
    },
    addFile() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.$route.params.id +
            "/files",
          { content: this.file }
        )
        .then(({ data }) => {
          this.file = "";
          this.$EventBus.$emit("alert", { message: data.message });
          this.fetchFiles();
        });
    },
  },
  mounted() {
    this.fetchFiles();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLL");
    },
  },
};
</script>

<style></style>
