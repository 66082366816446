<template>
  <div class="pt-3">
    <div class="row">
      <div class="col my-auto">
        <h5 class="mb-0">Emails</h5>
      </div>
      <div class="col-auto ms-auto my-auto" v-if="!showSend">
        <button class="btn btn-outline-success" @click="showSend = !showSend">
          <i class="far fa-plus me-2"></i>Send New
        </button>
      </div>
      <div class="col-auto ms-auto my-auto" v-else>
        <button class="btn btn-outline-success" @click="showSend = !showSend">
          <i class="far fa-times me-2"></i>Close
        </button>
      </div>
    </div>
    <hr class="mb-4" />

    <emails
      v-if="!showSend && !viewingEmail"
      :viewing="viewing"
      @selected="viewEmail"
    ></emails>
    <view-email
      :email="viewingEmail"
      v-if="viewingEmail && !showSend"
      @close="closeEmail"
      @reply="reply"
    ></view-email>

    <send-email
      v-if="showSend"
      :client="client"
      @close="showSend = false"
      :reply="replyToEmail"
    ></send-email>
  </div>
</template>

<script>
import Emails from "./email-partials/Emails";
import ViewEmail from "./email-partials/ViewEmail";
import SendEmail from "./email-partials/SendEmail";

export default {
  props: ["viewing", "client"],
  data() {
    return {
      showSend: false,
      viewingEmail: null,
      replyToEmail: null,
    };
  },
  watch: {
    showSend: {
      handler(val, old) {
        if (val == true) {
          this.viewingEmail = null;
        }
      },
    },
  },
  methods: {
    reply(email) {
      this.closeEmail();
      this.replyToEmail = email;
      this.showSend = true;
    },
    closeEmail() {
      this.viewingEmail = null;
      this.$router.replace({
        path: this.$route.currentPath,
        query: {},
        hash: "emails",
      });
    },
    viewEmail(email) {
      this.viewingEmail = email;
    },
  },
  components: {
    Emails,
    ViewEmail,
    SendEmail,
  },
};
</script>

<style></style>
