<template>
  <div class="container">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Booking ID</th>
          <th scope="col">Status</th>
          <th scope="col">Created</th>
        </tr>
      </thead>
      <tbody>
        <tr class="cursor-pointer" v-for="i in forms" :key="i.id">
          <td @click="selectForm(i)">{{ i.booking_id || "Not set" }}</td>
          <td @click="selectForm(i)" class="text-capitalize">
            {{ (i.status || "Not set") | formatStatus }}
          </td>
          <td @click="selectForm(i)">{{ i.created_at | formatDate }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      forms: [],
    };
  },
  methods: {
    selectForm(intake) {
      this.$emit("selected", intake);
    },
    deleteForm(intake) {
      if (confirm("Are you sure you wish to delete this intake form?")) {
        this.$axios
          .delete(process.env.VUE_APP_API_URL + "/intake-forms/" + intake.id)
          .then(({ data }) => {
            this.fetchForms();
          });
      }
    },
    fetchForms() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.$route.params.id +
            "/forms"
        )
        .then(({ data }) => {
          this.forms = data;
        });
    },
  },
  mounted() {
    this.$EventBus.$on("client-form-added", this.fetchForms());
    this.fetchForms();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatStatus(status) {
      return status.replace(/_/g, " ");
    },
  },
};
</script>

<style></style>
