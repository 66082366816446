<template>
  <div>
    <form @submit.prevent="sendEmail">
      <div class="row mb-4">
        <div class="col-md-6">
          <label for="">Pre-defined templates</label>
          <select
            v-model="selectedTemplate"
            @change="useTemplate"
            class="form-control text-capitalize"
          >
            <option value="" disabled>Select a template</option>
            <option v-for="t in templates" :key="t.id" :value="t">
              {{ t.title + " / " + t.type }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <input
            type="text"
            v-model="form.subject"
            class="form-control"
            placeholder="Email subject"
          />
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <vue-editor
            ref="editor"
            class="email-template-editor"
            v-model="form.message"
            required
          ></vue-editor>
        </div>
      </div>

      <div class="row">
        <div class="col-auto ms-auto">
          <button
            class="btn btn-success"
            :disabled="form.message.length < 3 || sending"
          >
            <i class="far fa-check me-2"></i>Send Email
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: ["client", "reply"],
  data() {
    return {
      form: {
        client_id: this.$route.params.id,
        message: this.reply
          ? this.reply.body_stripped
            ? "<br><br><p>--</p><br>" + this.reply.body_stripped
            : ""
          : "",
        subject: this.reply
          ? this.reply.subject
            ? "Re: " + this.reply.subject
            : ""
          : "",
      },
      templates: [],
      selectedTemplate: null,
      sending: false,
    };
  },
  methods: {
    useTemplate() {
      console.log("changing email to template");
      var subject = this.selectedTemplate.subject;
      var body = this.selectedTemplate.body;

      // Client first name
      body = body.replace("{{firstName}}", this.client.first_name);

      // User signature
      if (this.$store.user.email_signature) {
        body = body.replace(
          "{{mySignature}}",
          this.$store.user.email_signature
        );
      } else {
        body = body.replace(
          "{{mySignature}}",
          "<p>Kind regards,</p><p>" + this.$store.user.name + "</p>"
        );
      }

      this.form.subject = subject;
      this.form.message = body;
    },
    fetchTemplates() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/email-templates")
        .then(({ data }) => {
          this.templates = data;
        });
    },
    sendEmail() {
      this.sending = true;
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.$route.params.id +
            "/send-email",
          this.form
        )
        .then(({ data }) => {
          this.form.subject = "";
          this.form.message = "";
          this.$EventBus.$emit("alert", data);
          this.sending = false;
          this.$emit("close");
        });
    },
  },
  mounted() {
    this.fetchTemplates();
    this.$nextTick(() => {
      this.$refs.editor.quill.focus();
    });
  },
};
</script>

<style></style>
