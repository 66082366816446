<template>
  <div class="container">
    <ul class="list-group list-group-flush" v-if="!busy">
      <li
        class="list-group-item cursor-pointer"
        v-for="(e, eKey) in emails"
        :key="e.type + '-' + e.id"
        :class="eKey % 2 == 0 ? 'bg-light' : ''"
        @click="selectEmail(e)"
      >
        <div class="row">
          <div class="col-md-1">
            <i
              class="far"
              :class="e.type == 'inbound' ? 'fa-arrow-down' : 'fa-arrow-up'"
            ></i>
          </div>
          <div class="col my-auto">
            <p class="mb-0">
              <small
                v-html="
                  subjectBodyCombined(
                    e.subject,
                    e.body_stripped,
                    e.status == 'dealt_with' ? true : false
                  )
                "
              ></small>
            </p>
          </div>
          <div class="col-auto my-auto ms-auto">
            <p class="mb-0">
              <small>{{ e.created_at | formatDate }}</small>
            </p>
          </div>
        </div>
      </li>
    </ul>
    <loading v-else></loading>
  </div>
</template>

<script>
import Loading from "../../../../components/loaders/Loading";

export default {
  props: ["viewing"],
  data() {
    return {
      emails: [],
      busy: false,
    };
  },
  methods: {
    selectEmail(email) {
      this.$emit("selected", email);
      if (this.$route.query.email != email.id) {
        this.$router.replace({
          path: this.$route.currentPath,
          query: {
            email: email.id,
          },
          hash: "emails",
        });
      }
    },
    deleteEmail(email) {
      if (confirm("Are you sure you wish to delete this email form?")) {
        this.$axios
          .delete(process.env.VUE_APP_API_URL + "/emails/" + email.id)
          .then(({ data }) => {
            this.fetchEmails();
          });
      }
    },
    fetchEmails() {
      this.busy = true;
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.$route.params.id +
            "/emails"
        )
        .then(({ data }) => {
          this.emails = data;
          this.checkRequestedEmail();
          this.busy = false;
        });
    },
    checkRequestedEmail() {
      if (this.$route.query.email) {
        var foundEmail = this.emails.find(
          (e) => e.id == this.$route.query.email
        );

        if (foundEmail) {
          this.selectEmail(foundEmail);
        }
      }
    },
    subjectBodyCombined(subject, body, read) {
      if (!subject) {
        subject = "(no subject)";
      }
      if (subject) {
        if (subject.length > 40) {
          if (read) {
            var newSubject = subject.substring(0, 40) + "...";
          } else {
            var newSubject =
              "<strong>" + subject.substring(0, 40) + "...</strong>";
          }
        } else {
          var newSubject = subject;
        }
      } else {
        var newSubject = "";
      }

      if (body && body.length > 70) {
        var newBody = body.substring(0, 70) + "...";
      } else {
        var newBody = body;
      }

      return (newSubject + " - " + newBody).replace(/(<([^>]+)>)/gi, "");
    },
  },
  mounted() {
    this.$EventBus.$on("client-email-added", this.fetchEmails());
    this.fetchEmails();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("L LT");
    },
    formatCallBackDate(date) {
      if (date) {
        return moment(date).format("LLL");
      }
      return "Not Set";
    },
    shortenPhrase(string) {
      if (string.length > 30) {
        return string.substring(0, 30) + "...";
      }
      return string;
    },
  },
  components: {
    Loading,
  },
};
</script>

<style></style>
