<template>
  <div>
    <div class="bg-white py-4">
      <div class="row">
        <div class="col-auto my-auto">
          <h4 class="fw-bold text-success">SMS</h4>
        </div>
        <div class="col my-auto">
          <textarea
            v-model="newSms"
            rows="2"
            class="form-control"
            placeholder="New sms..."
          ></textarea>
        </div>
        <div class="col-auto my-auto ms-auto">
          <button class="btn btn-sm btn-success" @click="sendSms">
            <i class="far fa-plus me-2"></i>Send SMS
          </button>
        </div>
      </div>
    </div>
    <!-- SMS -->
    <div class="row mb-4" v-for="m in messages" :key="m.id">
      <div class="col-md-8" :class="m.type == 'out' ? 'ms-auto' : 'me-auto'">
        <div class="row">
          <div class="col">
            <p
              class="p-3"
              :class="
                m.type == 'out' ? 'bg-success text-white' : 'bg-dark text-white'
              "
            >
              {{ m.message }}
            </p>
          </div>
        </div>
        <small
          ><i>{{ m.created_at | formatDate }}</i></small
        >
      </div>
    </div>

    <!-- None -->

    <div class="row py-4" v-if="messages.length == 0">
      <div class="col text-center">
        <h4 class="fw-bold text-success">No messages yet</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      messages: [],
      newSms: "",
    };
  },
  methods: {
    fetchSms() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.$route.params.id +
            "/sms"
        )
        .then(({ data }) => {
          this.messages = data;
        });
    },
    sendSms() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.$route.params.id +
            "/sms",
          { message: this.newSms }
        )
        .then(({ data }) => {
          this.newSms = "";
          this.$EventBus.$emit("alert", data);
          this.fetchSms();
        });
    },
  },
  created() {
    this.fetchSms();
  },
  filters: {
    formatDate: function (date) {
      return moment(date).format("LLL");
    },
  },
};
</script>

<style>
</style>