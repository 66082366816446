<template>
  <div>
    <form @submit.prevent="addEnquiry">
      <div class="row mb-4">
        <div class="col">
          <textarea
            class="form-control"
            v-model="form.content"
            rows="4"
            placeholder="Enquiry comments / questions..."
          ></textarea>
        </div>
      </div>

      <div class="row">
        <div class="col-auto ms-auto">
          <button class="btn btn-success" :disabled="form.content.length < 3">
            <i class="far fa-check me-2"></i>Add Enquiry
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        content: "",
        client_id: this.$route.params.id,
        status: "new",
        source: "crm",
      },
    };
  },
  methods: {
    addEnquiry() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/enquiries", this.form)
        .then(({ data }) => {
          this.form.content = "";
          this.$EventBus.$emit("alert", data);
          this.$emit("close");
        });
    },
  },
};
</script>

<style>
</style>