<template>
  <div class="pt-3">
    <div class="row my-5" v-if="!$can('view client data')">
      <div class="col-auto mx-auto">
        <h5 class="fw-bold">You do not have permission to view this area</h5>
      </div>
    </div>
    <div v-else>
      <div class="row mb-4">
        <div class="col-auto my-auto">
          <h5 class="mb-0">Client Details</h5>
        </div>
        <div class="col-auto ms-auto my-auto"></div>
      </div>

      <!--  -->

      <form @submit.prevent="updateClientInfo">
        <div class="row mb-3">
          <div class="col">
            <label for="fname">First Name*</label>
            <input
              type="text"
              id="fname"
              class="form-control"
              v-model="clientCopy.first_name"
              @change="updateClientInfo"
              required
            />
          </div>
          <div class="col">
            <label for="lname">Last Name*</label>
            <input
              type="text"
              id="lname"
              class="form-control"
              v-model="clientCopy.last_name"
              @change="updateClientInfo"
              required
            />
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-6">
            <label for="email">Email Address*</label>
            <input
              type="email"
              id="email"
              class="form-control"
              v-model="clientCopy.email"
              @change="updateClientInfo"
              required
            />
          </div>

          <div class="col-6">
            <label for="email">Email Address (secondary)</label>
            <input
              type="email"
              id="secondaryemail"
              class="form-control"
              v-model="clientCopy.secondary_email"
              @change="updateClientInfo"
            />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-6">
            <label for="phone">Phone Number (Primary)</label>
            <phone-number-input
              v-model="clientCopy.phone"
              default-country-code="GB"
              :preferred-countries="['GB', 'MT', 'GR', 'ES', 'FR', 'BE', 'DE']"
              :clearable="true"
              @update="updatePhone"
            ></phone-number-input>
          </div>
          <div class="col-6">
            <label for="phone_2">Phone Number (Secondary)</label>
            <phone-number-input
              v-model="clientCopy.mobile"
              default-country-code="GB"
              :preferred-countries="['GB', 'MT', 'GR', 'ES', 'FR', 'BE', 'DE']"
              :clearable="true"
              @update="updatePhone"
            ></phone-number-input>
          </div>
        </div>
        <div class="row">
          <div class="col-auto ms-auto">
            <button
              class="btn btn-sm btn-outline-success"
              @click="updateClientInfo"
              :disabled="loading || !clientCopy.first_name || !clientCopy.last_name || !clientCopy.email"
            >
              <i class="fad fa-save me-2"></i>
              Save
            </button>
          </div>
        </div>
      </form>
      <hr class="my-3" />
      <div class="row" v-if="addNewPrimaryAddress">
        <div class="col">
          <div class="alert bg-light">
            <div class="row d-flex justify-content-between align-items-center mb-2">
              <div class="col">
                <h5 class="d-flex align-items-center mb-0">New Primary Address</h5>
              </div>
              <div class="col text-right">
                <span
                  @click="closeNewAddress"
                  class="d-flex justify-content-end cursor-pointer"
                  v-tooltip:bottom="'Close'"
                >
                  <i class="fad fa-times fa-2x"></i>
                </span>
              </div>
            </div>
            <form @submit.prevent="saveAddress">
              <div class="row mb-4">
                <div class="col">
                  <label for>Address 1*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="newAddress1"
                    required
                  />
                </div>
                <div class="col">
                  <label for>Address 2</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="newAddress2"
                  />
                </div>
              </div>

              <div class="row mb-4">
                <div class="col">
                  <label for>Town</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="newAddressTown"
                  />
                </div>
                <div class="col">
                  <label for>City</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="newAddressCity"
                  />
                </div>
              </div>

              <div class="row mb-4">
                <div class="col">
                  <label for>County</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="newAddressCounty"
                  />
                </div>
                <div class="col">
                  <label for>Post Code*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="newAddressPostCode"
                    required
                  />
                </div>
                <div class="col">
                  <label for>Country*</label>
                  <country-select
                    v-model="newAddressCountry"
                    required
                  ></country-select>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col text-right">
                  <button class="btn btn-outline-success" :disabled="loading">
                    <i class="fad fa-save me-2"></i>
                    <span>Save Primary Address</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-6">
          <div class="card border-0 shadow-sm bg-light w-100">
            <div class="card-header d-flex align-items-center justify-content-between bg-success text-white border-0">
              <span><i class="fas fa-house me-2"></i>Primary Address</span>
              <span class="float-end">
                <button
                  class="btn btn-outline-light btn-sm"
                  v-show="
                    !addNewPrimaryAddress &&
                    !client.address
                  "
                  @click="addNewPrimaryAddress = true"
                >
                  <i class="fad fa-plus me-2"></i>New
                </button>
              </span>
            </div>
            <div class="card-body">
              <div v-if="clientCopy.addresses.length > 0" class="row">
                <div class="col" v-for="address in clientCopy.addresses" :key="address.id">
                  <address-inline
                    :a="address"
                    :client="clientCopy"
                    :show-delete="true"
                  ></address-inline>
                </div>
              </div>
              <div v-else-if="!addNewPrimaryAddress" class="row">
                <div class="col text-center">
                  <i class="fas fa-house fa-5x text-success"></i>
                  <p class="mt-2">No primary address found.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <welcome-email-modal :user="user" :client="client"></welcome-email-modal> -->
    </div>
  </div>
</template>

<script>
import AddressInline from "./address-partials/AddressInline";
import CountrySelect from "../../../globals/CountrySelect";
import debounce from "debounce";
// import WelcomeEmailModal from "./email-partials/WelcomeEmailModal";

export default {
  props: ["client", "user"],
  data() {
    return {
      addNewPrimaryAddress: false,
      newAddressTitle: "",
      newAddress1: "",
      newAddress2: "",
      newAddressTown: "",
      newAddressCity: "",
      newAddressCounty: "",
      newAddressPostCode: "",
      newAddressCountry: "",
      editingAdress: null,
      clientCopy: this.client,
      loading: false
    };
  },
  methods: {
    editAddress(a) {
      this.editingAdress = a;
    },
    closeNewAddress() {
      this.addNewPrimaryAddress = false;
    },
    saveAddress: debounce(function() {
      this.loading = true;

      let address = {
        line_1: this.newAddress1,
        line_2: this.newAddress2,
        town: this.newAddressTown,
        city: this.newAddressCity,
        county: this.newAddressCounty,
        post_code: this.newAddressPostCode,
        country: this.newAddressCountry,
        label: this.newAddressTitle
      }

      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.clientCopy.id +
            "/addresses",
            address
        )
        .then(({ data }) => {
          this.addNewPrimaryAddress = false;
          this.newAddressTitle = "";
          this.newAddress1 = "";
          this.newAddress2 = "";
          this.newAddressTown = "";
          this.newAddressCity = "";
          this.newAddressCounty = "";
          this.newAddressPostCode = "";
          this.newAddressCountry = "";

          this.loading = false;
          this.$EventBus.$emit("alert", data);

          if (data.address) {
            this.clientCopy.addresses.push(data.address);
          }

          //quickfix
          // window.location = "/clients/" + this.clientCopy.id + "#details";
          // window.location.reload();
        });
    }, 1000),
    updateClientInfo: debounce(function() {
      if (!this.clientCopy.first_name || !this.clientCopy.last_name || !this.clientCopy.email) {
        this.$EventBus.$emit("alert", {
          color: "danger", message: "Please fill out the name and email fields."
        });
        return;
      }

      this.loading = true;

      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/clients/" + this.clientCopy.id,
          this.clientCopy
        )
        .then(({ data }) => {
          this.loading = false;
          this.$EventBus.$emit("refreshClient");
          this.$EventBus.$emit("alert", data);
        });
    }, 1000),
    updatePhone(input) {
      if (input.isValid) {
        this.$nextTick(() => {
          this.updateClientInfo();
        });
      }
    },
    showWelcomeEmailModal() {
      $("#welcomeEmailModal").modal("show");
    },
  },
  mounted() {
    var vm = this;
    this.$EventBus.$on("addressRemoved", function (removed) {
      var addresses = vm.clientCopy.addresses;
      var newList = [];
      Object.keys(addresses).forEach((key) => {
        let address = addresses[key];
        if (address.id !== removed.id) {
          newList.push(address);
        }
      });
      vm.clientCopy.addresses = newList;
    });
  },
  components: {
    // WelcomeEmailModal,
    CountrySelect,
    AddressInline,
  },
};
</script>

<style></style>
