<template>
  <div class="pt-3">
    <div class="row" v-if="!viewingRetreatBooking">
      <div class="col my-auto">
        <h5 class="mb-0">Retreat Bookings</h5>
      </div>
      <div class="col-auto ms-auto my-auto" v-if="!showAdd">
        <button class="btn btn-outline-success" @click="addNewBooking">
          <i class="far fa-plus me-2"></i>Add New
        </button>
      </div>
      <div class="col-auto ms-auto my-auto" v-else>
        <button class="btn btn-outline-success" @click="showAdd = !showAdd">
          <i class="far fa-times me-2"></i>Close
        </button>
      </div>
    </div>
    <hr class="mb-4" v-if="!viewingRetreatBooking" />

    <retreat-bookings
      v-if="!showAdd && !viewingRetreatBooking"
      :viewing="viewing"
      @selected="viewEnquiry"
    ></retreat-bookings>
    <view-retreat-booking
      :retreat-booking="viewingRetreatBooking"
      v-if="viewingRetreatBooking && !showAdd"
      @close="closeEnquiry"
    ></view-retreat-booking>
  </div>
</template>

<script>
import RetreatBookings from "./retreat-booking-partials/RetreatBookings";
import ViewRetreatBooking from "./retreat-booking-partials/ViewRetreatBooking";

export default {
  props: ["viewing"],
  data() {
    return {
      showAdd: false,
      viewingRetreatBooking: null,
    };
  },
  watch: {
    showAdd: {
      handler(val, old) {
        if (val == true) {
          this.viewingRetreatBooking = null;
        }
      },
    },
  },
  methods: {
    addNewBooking() {
      this.$router.push({
        path: "/bookings/create",
        query: { client: this.$route.params.id },
      });
    },
    closeEnquiry() {
      this.viewingRetreatBooking = null;
      this.$router.replace({
        path: this.$route.currentPath,
        query: {},
        hash: "retreat-bookings",
      });
    },
    viewEnquiry(retreatBooking) {
      this.viewingRetreatBooking = retreatBooking;
    },
  },
  components: {
    RetreatBookings,
    ViewRetreatBooking,
  },
};
</script>

<style></style>
