<template>
  <div class="container">
    <table class="table" v-if="!busy">
      <thead>
        <tr>
          <th scope="col">ID</th>

          <th scope="col">Retreat</th>
          <th scope="col">Clients</th>

          <th scope="col">Arrival</th>
          <th scope="col">Stay</th>
          <th scope="col">Status</th>
          <th scope="col">Type</th>
          <th scope="col">Created</th>
        </tr>
      </thead>
      <tbody v-if="retreatBookings.length > 0">
        <tr class="cursor-pointer" v-for="i in retreatBookings" :key="i.id">
          <td @click="selectRetreatBooking(i)">{{ i.id }}</td>

          <td
            @click="selectRetreatBooking(i)"
            :class="i.retreat == 'uk' ? 'text-uppercase' : 'text-capitalize'"
          >
            {{ i.retreat }}
          </td>
          <td @click="selectRetreatBooking(i)">{{ i.clients.length }}</td>
          <td @click="selectRetreatBooking(i)">
            {{ i.arrival_date | formatDate }}
          </td>
          <td @click="selectRetreatBooking(i)">{{ i.total_nights }} nights</td>
          <td class="text-capitalize" @click="selectRetreatBooking(i)">
            {{ i.room_bookings.length > 0 ? i.room_bookings[0].payment_status : null | formatStatus}}
          </td>
          <td class="text-capitalize" @click="selectRetreatBooking(i)">
            {{ i.room_bookings.length > 0 ? i.room_bookings[0].type : null | formatStatus}}
          </td>
          <td @click="selectRetreatBooking(i)">
            {{ i.created_at | formatDate }}
          </td>
        </tr>
      </tbody>
      <tbody v-else><p>No room bookings available</p></tbody>
    </table>
    <loading v-else></loading>
  </div>
</template>

<script>
import Loading from "../../../../components/loaders/Loading";

export default {
  props: ["viewing"],
  data() {
    return {
      retreatBookings: [],
      busy: false,
    };
  },
  methods: {
    selectRetreatBooking(retreatBooking) {
      this.$emit("selected", retreatBooking);
      if (this.$route.query["retreat-booking"] != retreatBooking.id) {
        this.$router.replace({
          path: this.$route.currentPath,
          query: {
            "retreat-booking": retreatBooking.id,
          },
          hash: "retreat-bookings",
        });
      }
    },
    deleteRetreatBooking(retreatBooking) {
      if (
        confirm("Are you sure you wish to delete this retreatBooking form?")
      ) {
        this.$axios
          .delete(
            process.env.VUE_APP_API_URL +
              "/retreat-booking/" +
              retreatBooking.id
          )
          .then(({ data }) => {
            this.fetchRetreatBookings();
          });
      }
    },
    fetchRetreatBookings() {
      this.busy = true;
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.$route.params.id +
            "/retreat-bookings"
        )
        .then(({ data }) => {
          this.retreatBookings = data;
          this.checkRequestedRetreatBooking();
          this.busy = false;
        });
    },
    checkRequestedRetreatBooking() {
      if (this.$route.query["retreat-booking"]) {
        var foundRetreatBooking = this.retreatBookings.find(
          (e) => e.id == this.$route.query["retreat-booking"]
        );

        if (foundRetreatBooking) {
          this.selectRetreatBooking(foundRetreatBooking);
        }
      }
    },
  },
  mounted() {
    this.$EventBus.$on(
      "client-retreatBooking-added",
      this.fetchRetreatBookings()
    );
    this.fetchRetreatBookings();

    this.$EventBus.$on("refreshBookingData", this.fetchRetreatBookings());
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatStatus(status) {
      if (status) {
        return status.replace(/_/g, " ");
      }
      return "Not set";
    },
  },
  components: {
    Loading,
  },
};
</script>

<style></style>
