<template>
  <div class="container-fluid" id="client-profile">
    <div class="row" v-if="client">
      <div class="col-3" style="height: 85vh">
        <div class="card border-0 shadow-sm mb-3 h-100">
          <div class="card-header border-0 bg-white mb-2 pb-0">
            <div class="row mb">
              <div class="col">
                <div class="row mb-2">
                  <div class="col">
                    <h5 class="text-success mb-1 d-inline">
                      {{ client.name }}
                    </h5>
                    <span
                      v-if="client.status === 'contact'"
                      class="float-end badge badge-primary mt-1"
                      >Contact</span
                    >
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col">
                    <!-- <button
                      class="btn btn-outline-primary btn-sm me-2"
                      v-if="client.status !== 'contact' && client.active"
                      @click="toggleClientActive"
                    >
                      <i class="fad fa-check me-2"></i>Active
                    </button>

                    <button
                      class="btn btn-outline-danger btn-sm me-2"
                      @click="toggleClientActive"
                      v-if="!client.active"
                      v-tooltip:bottom="
                        'Client is inactive. Please provide First Name, Last Name and E-mail Address' +
                        (client.is_child ? ' for parent client.' : '.')
                      "
                    >
                      <i class="fad fa-times me-2"></i>Inactive
                    </button> -->

                    <button
                      v-if="client.status !== 'contact'"
                      class="btn btn-outline-success w-100 me-2 mb-2"
                      @click="loginAsClient(client)"
                    >
                      <i class="fad fa-eye me-2"></i>View Client Portal
                    </button>

                    <button
                      v-else
                      class="btn btn-light w-100 me-2 mb-2"
                      @click="convertToClient()"
                    >
                      <i class="fad fa-user me-2"></i>Convert to Client
                    </button>

                    <button
                      v-if="$can('manage client data')"
                      class="btn btn-outline-danger w-100 me-2 mb-2"
                      @click="deleteClient()"
                    >
                      <i class="fad fa-trash me-2"></i>Delete
                    </button>

                  </div>
                </div>

                <small
                  class="bg-light mt-2 mb-2 p-2 d-block text-center"
                  v-if="client.parent != null"
                >
                  Child's profile linked to
                  <a :href="'/clients/' + client.parent.id">{{
                    client.parent.name
                  }}</a>
                </small>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <ul class="list-group" id="myTab" role="tablist">
                  <li
                    class="list-group-item p-2 d-flex justify-content-between align-items-center"
                    v-if="client.parent == null"
                  >
                    <span
                      v-if="!editingEmail"
                      :class="!$can('view client data') ? 'blur-effect' : ''"
                    >
                      <small class="">{{
                        client.email
                          ? client.email
                          : "Email Not Set (Mandatory)"
                      }}</small>
                    </span>
                    <input
                      v-if="editingEmail"
                      autofocus
                      type="text"
                      class="form-control me-3"
                      @keyup.enter="updateClientInfo"
                      v-model="client.email"
                    />
                    <span class="text-nowrap" v-if="$can('view client data')">
                      <i
                        class="fas fa-save text-success cursor-pointer me-1"
                        @click="updateClientInfo"
                        v-if="editingEmail"
                      ></i>
                      <i
                        class="fas fa-edit ms-auto cursor-pointer"
                        @click="editingEmail = !editingEmail"
                      ></i>
                    </span>
                  </li>

                  <!-- <li
                    class="list-group-item p-2 d-flex justify-content-between align-items-center"
                    v-if="client.parent == null"
                  >
                    <span
                      v-if="!editingSecondaryEmail"
                      :class="!$can('view client data') ? 'blur-effect' : ''"
                    >
                      <small class="">{{
                        client.secondary_email
                          ? client.secondary_email
                          : "Secondary Email Not Set"
                      }}</small>
                    </span>
                    <input
                      v-if="editingSecondaryEmail"
                      autofocus
                      type="text"
                      class="form-control me-3"
                      @keyup.enter="updateClientInfo"
                      v-model="client.secondary_email"
                    />
                    <span class="text-nowrap" v-if="$can('view client data')">
                      <i
                        class="fas fa-save text-success cursor-pointer me-1"
                        @click="updateClientInfo"
                        v-if="editingSecondaryEmail"
                      ></i>
                      <i
                        class="fas fa-edit ms-auto cursor-pointer"
                        @click="editingSecondaryEmail = !editingSecondaryEmail"
                      ></i>
                    </span>
                  </li> -->

                  <li
                    class="list-group-item p-2 d-flex justify-content-between align-items-center"
                    v-if="client.parent == null"
                  >
                    <span
                      v-if="!editingPhone"
                      :class="!$can('view client data') ? 'blur-effect' : ''"
                    >
                      <small>{{
                        client.phone ? client.phone : "Primary Number Not Set"
                      }}</small>
                    </span>
                    <phone-number-input
                      v-if="editingPhone"
                      autofocus
                      v-model="client.phone"
                      default-country-code="GB"
                      :preferred-countries="[
                        'GB',
                        'MT',
                        'GR',
                        'ES',
                        'FR',
                        'BE',
                        'DE',
                      ]"
                      :clearable="true"
                      @update="updatePhone"
                    ></phone-number-input>
                    <span class="text-nowrap" v-if="$can('view client data')">
                      <i
                        class="fas fa-save text-success cursor-pointer me-1 ms-3"
                        @click="updateClientInfo"
                        v-if="editingPhone"
                      ></i>
                      <i
                        class="fas fa-edit ms-auto cursor-pointer"
                        @click="editingPhone = !editingPhone"
                      ></i>
                    </span>
                  </li>
                  <!--
                  <li
                    class="list-group-item p-2 d-flex justify-content-between align-items-center"
                    v-if="client.parent == null"
                  >
                    <span
                      v-if="!editingMobile"
                      :class="!$can('view client data') ? 'blur-effect' : ''"
                    >
                      <small>{{
                        client.mobile
                          ? client.mobile
                          : "Secondary Number Not Set"
                      }}</small>
                    </span>
                    <phone-number-input
                      v-if="editingMobile"
                      autofocus
                      v-model="client.mobile"
                      default-country-code="GB"
                      :preferred-countries="[
                        'GB',
                        'MT',
                        'GR',
                        'ES',
                        'FR',
                        'BE',
                        'DE',
                      ]"
                      :clearable="true"
                      @update="updatePhone"
                    ></phone-number-input>
                    <span class="text-nowrap" v-if="$can('view client data')">
                      <i
                        class="fas fa-save text-success cursor-pointer me-1 ms-3"
                        @click="updateClientInfo"
                        v-if="editingMobile"
                      ></i>
                      <i
                        class="fas fa-edit ms-auto cursor-pointer"
                        @click="editingMobile = !editingMobile"
                      ></i>
                    </span>
                  </li> -->

                  <li
                    class="list-group-item p-2 d-flex justify-content-between align-items-center"
                  >
                    <span
                      v-if="!editingDob"
                      :class="!$can('view client data') ? 'blur-effect' : ''"
                    >
                      <small v-if="client.dob != null">{{
                        client.dob | formatDate
                      }}</small>
                      <small v-else>DoB Not Set</small>
                    </span>
                    <date-input
                      v-if="editingDob"
                      id="dob"
                      :show-formatted="false"
                      :clear-button="true"
                      v-model="client.dob"
                      @change="updateClientInfo"
                    ></date-input>
                    <span class="text-nowrap" v-if="$can('view client data')">
                      <i
                        class="fas fa-save text-success cursor-pointer me-1"
                        @click="updateClientInfo"
                        v-if="editingDob"
                      ></i>
                      <i
                        class="fas fa-edit ms-auto cursor-pointer"
                        @click="editingDob = !editingDob"
                      ></i>
                    </span>
                  </li>

                  <li
                    class="list-group-item p-2 d-flex justify-content-between align-items-center"
                  >
                    <span v-if="!editingGender">
                      <small v-if="client.gender != null">{{
                        client.gender | capitalize
                      }}</small>
                      <small v-else>Gender not set</small>
                    </span>
                    <select
                      @change="updateClientInfo"
                      class="form-control form-control-sm w-100 me-2"
                      v-model="client.gender"
                      v-if="editingGender"
                    >
                      <option value="female">Female</option>
                      <option value="male">Male</option>
                    </select>
                    <span class="text-nowrap">
                      <i
                        class="fas fa-save text-success cursor-pointer me-1"
                        @click="updateClientInfo"
                        v-if="editingGender"
                      ></i>
                      <i
                        class="fas fa-edit ms-auto cursor-pointer"
                        @click="editingGender = !editingGender"
                      ></i>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body h-100" style="overflow: scroll">
            <!--  -->

            <!-- New Nav -->

            <div
              class="nav flex-column nav-pills"
              id="client-tabs"
              role="tablist"
              aria-orientation="vertical"
            >
              <a
                v-for="t in tabs"
                :key="t.id"
                @click.stop.prevent="setActive(t)"
                class="nav-link"
                :class="[t.class, { active: t.isActive }]"
                :id="t.id + '-tab'"
                data-bs-toggle="tab"
                :data-bs-target="'#' + t.id"
                type="button"
                role="tab"
                :aria-controls="t.id"
                :aria-selected="t.isActive"
              >
                <div class="row">
                  <div class="col-2 text-center my-auto">
                    <i
                      class="fad"
                      :class="
                        t.isActive
                          ? t.icon + ' text-white'
                          : t.icon + ' text-success'
                      "
                    ></i>
                  </div>
                  <div class="col my-auto">{{ t.name }}</div>
                  <span class="client-unread-counter me-2" v-if="t.counter">{{
                    t.counter
                  }}</span>
                </div>
              </a>
            </div>

            <!-- End New Nav -->

            <!--  -->

            <!-- <div class="row mt-3 mb-0 text-center">
              <div class="col">
                <ul class="list-group mb-3">
                  <li
                    class="list-group-item p-2 d-flex justify-content-between align-items-center"
                  >
                    <span v-if="!editingBecameClient">
                      <small v-if="client.became_client != null">{{
                        client.became_client | formatDate
                      }}</small>
                      <small v-else>Became Client Not Set</small>
                    </span>
                    <date-input
                      v-if="editingBecameClient"
                      id="becameClient"
                      :show-formatted="false"
                      :clear-button="true"
                      v-model="client.became_client"
                      @change="updateClientInfo"
                    ></date-input>
                    <span class="text-nowrap">
                      <i
                        class="fas fa-save text-success cursor-pointer me-1"
                        @click="updateClientInfo"
                        v-if="editingBecameClient"
                      ></i>
                      <i
                        class="fas fa-edit ms-auto"
                        @click="editingBecameClient = !editingBecameClient"
                      ></i>
                    </span>
                  </li>
                </ul>
                <p class="mb-0 text-muted">
                  <small>
                    <i v-if="client.became_client != null"
                      >Client since {{ client.became_client | formatDate }}</i
                    >
                  </small>
                </p>
              </div>
            </div> -->
          </div>
        </div>
        <!--  -->
      </div>
      <!--  -->
      <div class="col-9 pl-0" style="height: 85vh" id="clientViewTab">
        <div class="card border-0 shadow-sm h-100">
          <div class="card-body h-100 pt-0" style="overflow: scroll">
            <div class="tab-content" id="v-pills-tabContent">
              <div
                class="tab-pane fade show"
                v-for="t in tabs"
                :key="t.id"
                :class="{ active: t.isActive }"
                :id="t.id"
                role="tabpanel"
                :aria-labelledby="t.id + '-tab'"
              >
                <client-details-tab
                  v-if="
                    t.id == 'client-details' &&
                    activeTab &&
                    activeTab.id == t.id
                  "
                  :user="user"
                  :client="client"
                ></client-details-tab>

                <enquiries-tab
                  v-if="
                    t.id == 'enquiries' && activeTab && activeTab.id == t.id
                  "
                  :user="user"
                  :client="client"
                  :viewing="$route.query.enquiry"
                ></enquiries-tab>

                <handovers-tab
                  v-if="
                    t.id == 'handovers' && activeTab && activeTab.id == t.id
                  "
                  :user="user"
                  :client="client"
                  :viewing="$route.query.enquiry"
                ></handovers-tab>

                <sms-tab
                  v-if="t.id == 'sms' && activeTab && activeTab.id == t.id"
                  :user="user"
                  :client="client"
                  :viewing="$route.query.enquiry"
                ></sms-tab>

                <emails-tab
                  v-if="t.id == 'emails' && activeTab && activeTab.id == t.id"
                  :user="user"
                  :client="client"
                  :viewing="$route.query.email"
                ></emails-tab>

                <files-tab
                  v-if="t.id == 'files' && activeTab && activeTab.id == t.id"
                  :user="user"
                  :client="client"
                  :viewing="$route.query.email"
                ></files-tab>

                <retreat-bookings-tab
                  v-if="
                    t.id == 'retreat-bookings' &&
                    activeTab &&
                    activeTab.id == t.id
                  "
                  :user="user"
                  :client="client"
                  :viewing="$route.query.enquiry"
                ></retreat-bookings-tab>

                <intake-forms-tab
                  v-if="
                    t.id == 'intake-forms' && activeTab && activeTab.id == t.id
                  "
                  :user="user"
                  :client="client"
                ></intake-forms-tab>

                <forms-tab
                  v-if="t.id == 'forms' && activeTab && activeTab.id == t.id"
                  :user="user"
                  :client="client"
                ></forms-tab>

                <notes-tab
                  v-if="t.id == 'notes' && activeTab && activeTab.id == t.id"
                  :user="user"
                  :client="client"
                ></notes-tab>

                <treatments-tab
                  v-if="
                    t.id == 'treatments' && activeTab && activeTab.id == t.id
                  "
                  :user="user"
                  :client="client"
                ></treatments-tab>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="row">
      <div class="col">
        <loading height="40px" width="40px">Loading...</loading>
      </div>
    </div>
  </div>
</template>

<script>
import ClientDetailsTab from "./view-tabs/ClientDetailsTab";
import IntakeFormsTab from "./view-tabs/IntakeForms";
import FormsTab from "./view-tabs/Forms";
import EnquiriesTab from "./view-tabs/Enquiries";
import SmsTab from "./view-tabs/Sms";
import HandoversTab from "./view-tabs/Handovers";
import EmailsTab from "./view-tabs/Emails";
import FilesTab from "./view-tabs/Files";
import NotesTab from "./view-tabs/Notes";
import TreatmentsTab from "./view-tabs/Treatments";
import RetreatBookingsTab from "./view-tabs/RetreatBookings";
//
import Datepicker from "vuejs-datepicker";
import VueTagsInput from "@johmun/vue-tags-input";
import Loading from "../../components/loaders/Loading";

export default {
  data() {
    return {
      client: null,
      activeTab: {
        name: "Details",
        id: "client-details",
        isActive: true,
        icon: "fa-address-card",
        class: "",
      },
      editingEmail: false,
      editingSecondaryEmail: false,
      editingPhone: false,
      editingMobile: false,
      editingDob: false,
      editingGender: false,
      editingBecameClient: false,
      forms: null,
      tags: [],
      tag: "",
      autocompleteItems: [],
    };
  },
  computed: {
    tabs() {
      var tabs = [
        {
          name: "Details",
          id: "client-details",
          isActive: true,
          icon: "fa-address-card",
          class: "",
        },

        {
          name: "Enquiries",
          id: "enquiries",
          isActive: false,
          icon: "fa-envelope",
          class: "",
          permission: "manage enquiries",
        },
        {
          name: "Emails",
          id: "emails",
          isActive: false,
          icon: "fa-inbox",
          class: "",
          permission: "manage enquiries",
        },
        {
          name: "SMS",
          id: "sms",
          isActive: false,
          icon: "fa-mobile",
          class: "",
          permission: "manage enquiries",
        },
        {
          name: "Retreat Bookings",
          id: "retreat-bookings",
          isActive: false,
          icon: "fa-umbrella-beach",
          class: "",
          permission: "manage bookings",
        },

        // {
        //   name: "Intake Forms",
        //   id: "intake-forms",
        //   isActive: false,
        //   icon: "fa-align-justify",
        //   class: "",
        //   permission: "view intake forms",
        // },

        {
          name: "Forms (HQs)",
          id: "forms",
          isActive: false,
          icon: "fa-notes-medical",
          class: "",
          permission: "view forms",
        },

        {
          name: "Treatment Bookings",
          id: "treatments",
          isActive: false,
          icon: "fa-syringe",
          class: "",
          permission: "manage bookings",
        },

        {
          name: "Handovers",
          id: "handovers",
          isActive: false,
          icon: "fa-handshake",
          class: "",
          permission: "view client data",
        },
        {
          name: "360 Enquiries",
          id: "three-sixty-enquiries",
          isActive: false,
          icon: "fa-circle-notch",
          class: "",
          permission: "manage enquiries",
        },
        {
          name: "Notes",
          id: "notes",
          isActive: false,
          icon: "fa-notes-medical",
          class: "",
          permission: "view client data",
        },
        {
          name: "Consultations",
          id: "consultations",
          isActive: false,
          icon: "fa-user-md",
          class: "",
          permission: "view forms",
        },

        {
          name: "PRPs",
          id: "prps",
          isActive: false,
          icon: "fa-cart-plus",
          class: "",
          permission: "view forms",
        },
        {
          name: "Files",
          id: "files",
          isActive: false,
          icon: "fa-folders",
          class: "",
          permission: "view client data",
        },
      ];
      return tabs.filter((tab) => this.canSeeTab(tab.permission));
    },
    user() {
      return this.$store.user;
    },
  },
  methods: {
    canSeeTab(permission) {
      if (permission) {
        return this.$can(permission);
      } else {
        return true;
      }
    },
    setActive(tab) {
      var currentTab = this.$route.hash.replace("#", "");

      tab.isActive = true;
      this.activeTab = tab;
      this.tabs.forEach((tab) => {
        if (tab.id !== this.activeTab.id) {
          tab.isActive = false;
        }
      });

      if (tab.id != currentTab) {
        this.$router.replace({
          query: {},
          hash: tab.id,
        });
      }

      // this.$route.hash = tab.id;

      if (
        currentTab == "#retreat-bookings" &&
        this.$route.hash != "#retreat-bookings"
      ) {
        this.removeParamFromUrl("booking");
      }

      if (currentTab == "#enquiries" && this.$route.hash != "#enquiries") {
        this.removeParamFromUrl("enquiry");
      }

      if (currentTab == "#forms" && this.$route.hash != "#forms") {
        this.removeParamFromUrl("hq");
      }
    },

    setActiveTabFromHash() {
      var hash = this.$route.hash;

      if (hash) {
        this.setActive(this.tabs.find((x) => x.id === hash.replace("#", "")));
      } else {
        this.setActive(this.tabs.find((x) => x.id === "client-details"));
      }
    },

    removeParamFromUrl(parameter) {
      this.$router.replace({
        path: this.$route.currentPath,
        query: {},
        hash: this.$route.hash,
      });

      // var hash = window.location.hash;
      // var url = window.location.href;
      // var urlparts = url.split("?");
      // if (urlparts.length >= 2) {
      //   var urlBase = urlparts.shift();
      //   var queryString = urlparts.join("?");

      //   var prefix = encodeURIComponent(parameter) + "=";
      //   var pars = queryString.split(/[&;]/g);
      //   for (var i = pars.length; i-- > 0; )
      //     if (pars[i].lastIndexOf(prefix, 0) !== -1) pars.splice(i, 1);
      //   if (pars.length > 1) {
      //     url = urlBase + "?" + pars.join("&");
      //   } else {
      //     url = urlBase;
      //   }
      //   window.history.pushState("", window.title, url + hash); // added this line to push the new url directly to url bar .
      // }
      // return url + hash;
    },
    loginAsClient(client) {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            client.id +
            "/login-as-client"
        )
        .then(({ data }) => {
          window.open(data.url, "_blank");
        });
    },
    childProfileCreated(client) {
      const vm = this;
      setTimeout(() => {
        vm.$router.push("/clients/" + client.id);
      }, 1000);
    },
    createChildProfile() {
      $("#child-profile").modal("show");
    },
    autoComplete() {
      if (this.tag.length > 2) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL + "/autocomplete/tags/available-tags",
            {
              search: this.tag,
            }
          )
          .then(({ data }) => {
            this.autocompleteItems = data.map((t) => {
              return { text: t.name.en };
            });
          });
      }
    },
    deleteClient() {
      var result = confirm(
        "Are you sure you want to delete this client?"
      );
      if (result) {
        this.$axios
          .delete(
            process.env.VUE_APP_API_URL + "/clients/" + this.client.id
          )
          .then(({ data }) => {
            window.location = "/clients";
          });
      }
    },
    convertToClient() {
      this.client.status = "client";
      this.updateClientInfo();
    },
    updateClientInfo() {
      if (!this.client.first_name || !this.client.last_name || !this.client.email) {
        this.$EventBus.$emit("alert", {
          color: "danger", message: "Please fill out the required fields."
        });
        return;
      }

      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/clients/" + this.client.id,
          this.client
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.editingEmail = false;
          this.editingPhone = false;
          this.editingMobile = false;
          this.editingDob = false;
          this.editingGender = false;
          this.client = data.client;
        });
    },
    fetchClient() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/clients/" + this.$route.params.id)
        .then(({ data }) => {
          this.client = data;
          this.forms = data.forms;

          this.$EventBus.$emit("alert", data);
          this.editingEmail = false;
          this.editingPhone = false;
          this.editingMobile = false;
          this.editingDob = false;
          this.editingGender = false;

          this.setActiveTabFromHash();
        });
    },
    updatePhone(input) {
      if (input.isValid) {
        this.updateClientInfo();
      }
    }
  },
  watch: {
    $route: function (to, from) {
      if (from.hash !== to.hash) {
        console.log("hash change");
        this.activeTab = null;
        this.fetchClient();
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    if (to.params.id != from.params.id) {
      console.log("changing client");
      this.activeTab = null;
      this.fetchClient();
    }
  },
  created() {
    this.fetchClient();
  },
  beforeDestroy() {
    this.$store.sidebarOpen = true;
  },
  mounted() {
    // Hide the side bar for better viewing purposes
    this.$store.sidebarOpen = false;

    this.$EventBus.$on("refreshClient", (e) => {
      this.fetchClient();
    });
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  components: {
    ClientDetailsTab,
    IntakeFormsTab,
    FormsTab,
    SmsTab,
    Datepicker,
    HandoversTab,
    VueTagsInput,
    Loading,
    EnquiriesTab,
    EmailsTab,
    FilesTab,
    RetreatBookingsTab,
    NotesTab,
    TreatmentsTab,
  },
};
</script>

<style>
.nav-link .active i {
  color: #fff !important;
}

#client-profile .vue-tags-input {
  max-width: none;
  width: 100%;
}

#client-profile .ti-tag {
  background-image: linear-gradient(to right, #03006b, #33986a);
}

.client-unread-counter {
  display: block;
  background-color: red;
  border-radius: 50%;
  width: 25px;
  color: white;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
  z-index: 1;
  font-weight: bold;
}

.bg-danger-light {
  background-color: #f26561;
}
</style>

<style lang="scss">
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #026838 !important;
  color: white !important;
}

.nav-link i {
  color: #026838 !important;
}

.nav-link:hover {
  color: #026838 !important;
}

.nav-link.active i {
  color: white !important;
}

.blur-effect {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  background-color: #ccc;
}
</style>