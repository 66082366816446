<template>
  <div class="pt-3">
    <div class="row">
      <div class="col my-auto">
        <h5 class="mb-0">Enquiries</h5>
      </div>
      <div class="col-auto ms-auto my-auto" v-if="!showAdd">
        <button class="btn btn-outline-success" @click="showAdd = !showAdd">
          <i class="far fa-plus me-2"></i>Add New
        </button>
      </div>
      <div class="col-auto ms-auto my-auto" v-else>
        <button class="btn btn-outline-success" @click="showAdd = !showAdd">
          <i class="far fa-times me-2"></i>Close
        </button>
      </div>
    </div>
    <hr class="mb-4" />

    <enquiries
      v-if="!showAdd && !viewingEnquiry"
      :viewing="viewing"
      @selected="viewEnquiry"
    ></enquiries>
    <view-enquiry
      :enquiry="viewingEnquiry"
      v-if="viewingEnquiry && !showAdd"
      @close="closeEnquiry"
    ></view-enquiry>

    <add-enquiry v-if="showAdd" @close="showAdd = false"></add-enquiry>
  </div>
</template>

<script>
import Enquiries from "./enquiry-partials/Enquiries";
import ViewEnquiry from "./enquiry-partials/ViewEnquiry";
import AddEnquiry from "./enquiry-partials/AddEnquiry";

export default {
  props: ["viewing"],
  data() {
    return {
      showAdd: false,
      viewingEnquiry: null,
    };
  },
  watch: {
    showAdd: {
      handler(val, old) {
        if (val == true) {
          this.viewingEnquiry = null;
        }
      },
    },
  },
  methods: {
    closeEnquiry() {
      this.viewingEnquiry = null;
      this.$router.replace({
        path: this.$route.currentPath,
        query: {},
        hash: "enquiries",
      });
    },
    viewEnquiry(enquiry) {
      this.viewingEnquiry = enquiry;
    },
  },
  components: {
    Enquiries,
    ViewEnquiry,
    AddEnquiry,
  },
};
</script>

<style>
</style>