<template>
  <div>
    <div class="row mb-4">
      <div class="col">
        <h5 class="fw-bold" v-if="handover.booking_id">
          Attached to Booking #{{ handover.booking_id }}
        </h5>

        <h5 class="fw-bold" v-else>Not Attached to Booking</h5>
      </div>
      <div class="col-auto ms-auto">
        <button class="btn btn-sm btn-outline-success" @click="close">
          <i class="far fa-times"></i>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="card border-0 shadow-none bg-light">
          <div class="card-body">
            <div>{{ handover.note }}</div>
          </div>
        </div>

        <p class="">
          By {{ handover.user.name }} / Added
          {{ handover.created_at | formatDate }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["handover"],
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style>
</style>