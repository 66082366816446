<template>
  <div class="container">
    <table class="table" v-if="!busy">
      <thead>
        <tr>
          <th scope="col" style="width: 40%">Content</th>
          <th scope="col">Status</th>
          <th scope="col">Call Back</th>
          <th scope="col">Source</th>
          <th scope="col">Enquired</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="i in enquiries" :key="i.id">
          <td @click="selectEnquiry(i)">{{ i.content | shortenPhrase }}</td>
          <td @click="selectEnquiry(i)" class="text-capitalize">
            {{ i.status.replace(["_", "-"], " ") || "Not set" }}
          </td>
          <td @click="selectEnquiry(i)">
            {{ i.call_back_date | formatCallBackDate }}
          </td>
          <td @click="selectEnquiry(i)" class="text-capitalize">
            {{ i.source.replace(["_", "-"], " ") || "Not set" }}
          </td>
          <td @click="selectEnquiry(i)">{{ i.created_at | formatDate }}</td>
        </tr>
      </tbody>
    </table>
    <loading v-else></loading>
  </div>
</template>

<script>
import Loading from "../../../../components/loaders/Loading";

export default {
  props: ["viewing"],
  data() {
    return {
      enquiries: [],
      busy: false,
    };
  },
  methods: {
    selectEnquiry(enquiry) {
      this.$emit("selected", enquiry);
      if (this.$route.query.enquiry != enquiry.id) {
        this.$router.replace({
          path: this.$route.currentPath,
          query: {
            enquiry: enquiry.id,
          },
          hash: "enquiries",
        });
      }
    },
    deleteEnquiry(enquiry) {
      if (confirm("Are you sure you wish to delete this enquiry form?")) {
        this.$axios
          .delete(process.env.VUE_APP_API_URL + "/enquiry-forms/" + enquiry.id)
          .then(({ data }) => {
            this.fetchEnquiries();
          });
      }
    },
    fetchEnquiries() {
      this.busy = true;
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.$route.params.id +
            "/enquiries"
        )
        .then(({ data }) => {
          this.enquiries = data;
          this.checkRequestedEnquiry();
          this.busy = false;
        });
    },
    checkRequestedEnquiry() {
      if (this.$route.query.enquiry) {
        var foundEnquiry = this.enquiries.find(
          (e) => e.id == this.$route.query.enquiry
        );

        if (foundEnquiry) {
          this.selectEnquiry(foundEnquiry);
        }
      }
    },
  },
  mounted() {
    this.$EventBus.$on("client-enquiry-added", this.fetchEnquiries());
    this.fetchEnquiries();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("L");
    },
    formatCallBackDate(date) {
      if (date) {
        return moment(date).format("LLL");
      }
      return "Not Set";
    },
    shortenPhrase(string) {
      if (string) {
        if (string.length > 30) {
          return string.substring(0, 30) + "...";
        }
        return string;
      } else {
        return "no questions or comments";
      }
    },
  },
  components: {
    Loading,
  },
};
</script>

<style>
</style>