<template>
  <div class="container">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Booking Arrival</th>
          <th scope="col">Added by</th>
          <th scope="col">Created</th>
        </tr>
      </thead>
      <tbody>
        <tr class="cursor-pointer" v-for="i in handovers" :key="i.id">
          <td @click="selectHandover(i)">{{ i.booking.arrival_date | formatDate }}</td>
          <td @click="selectHandover(i)">{{ i.user.name || "n/a" }}</td>
          <td @click="selectHandover(i)">{{ i.created_at | formatDate }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      handovers: [],
    };
  },
  methods: {
    selectHandover(handover) {
      this.$emit("selected", handover);
    },
    deletehandover(handover) {
      if (confirm("Are you sure you wish to delete this handover form?")) {
        this.$axios
          .delete(process.env.VUE_APP_API_URL + "/handovers/" + handover.id)
          .then(({ data }) => {
            this.fetchHandovers();
          });
      }
    },
    fetchHandovers() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.$route.params.id +
            "/handovers"
        )
        .then(({ data }) => {
          this.handovers = data;
        });
    },
  },
  mounted() {
    this.$EventBus.$on("client-handover-forms-added", this.fetchHandovers());
    this.fetchHandovers();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
  },
};
</script>

<style>
</style>