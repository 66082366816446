<template>
  <div
    class="modal fade"
    id="notesViewModal"
    ref="notesModal"
    tabindex="-1"
    aria-labelledby="notesViewModalLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-capitalize" id="notesViewModalLabel">
            {{ model | formatModelName }} Notes
          </h5>

          <div class="mx-auto">
            <button
              class="btn btn-sm btn-outline-dark me-3"
              @click="sortNotes('desc')"
            >
              <i class="far fa-sort-size-down"></i>
            </button>

            <button
              class="btn btn-sm btn-outline-dark"
              @click="sortNotes('asc')"
            >
              <i class="far fa-sort-size-up"></i>
            </button>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body py-0">
          <!-- Add notes -->
          <div class="container">
            <div class="row">
              <div class="col-auto ms-auto">
                <!-- Sorting -->
              </div>
            </div>

            <!-- Notes -->

            <ul class="list-group list-group-flush" v-if="!busy">
              <li class="list-group-item" v-for="n in notes" :key="n.id">
                <div class="row">
                  <div class="col my-auto">
                    <div v-if="!editing">{{ n.content }}</div>
                    <div v-if="editing">
                      <textarea
                        rows="2"
                        v-model="n.content"
                        class="form-control"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-auto my-auto" v-if="!editing">
                    <button
                      class="btn btn-sm btn-outline-dark me-2"
                      @click="editNote(n)"
                    >
                      <i class="far fa-edit"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-outline-danger"
                      @click="deleteNote(n)"
                    >
                      <i class="far fa-trash"></i>
                    </button>
                  </div>

                  <div class="col-auto my-auto" v-if="editing">
                    <button class="btn btn-sm btn-success" @click="saveNote(n)">
                      <i class="far fa-check"></i>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <small v-if="n.user"
                      ><i>{{ n.user.name }} / </i></small
                    >
                    <small
                      ><i>{{ n.created_at | formatDate }}</i></small
                    >
                  </div>
                </div>
              </li>
            </ul>
            <loading v-else class="my-4"></loading>
          </div>
        </div>
        <div class="modal-footer">
          <div class="container-fluid">
            <div class="row">
              <div class="col my-auto">
                <textarea
                  v-model="note"
                  rows="2"
                  class="form-control"
                  placeholder="New note..."
                ></textarea>
              </div>
              <div class="col-auto my-auto">
                <button
                  type="button"
                  class="btn btn-success btn-sm"
                  @click="addModelNotes"
                >
                  Add note
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../../../components/loaders/Loading.vue";
export default {
  props: {
    model: String,
    modelId: Number
  },
  data() {
    return {
      notes: [],
      note: "",
      busy: false,
      editing: null
    };
  },
  methods: {
    sortNotes(direction) {
      if (direction == "desc") {
        this.notes.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
      } else {
        this.notes.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
      }
    },
    editNote(note) {
      this.editing = note;
    },
    deleteNote(note) {
      if (confirm("Are you sure you wish to delete this client note?")) {
        this.$axios
          .delete(process.env.VUE_APP_API_URL + "/client-notes/" + note.id)
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchModelNotes();
          });
      }
    },
    saveNote(note) {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/client-notes/" + note.id, note)
        .then(({ data }) => {
          this.editing = null;
          this.$EventBus.$emit("alert", data);
        });
    },
    fetchModelNotes() {
      this.busy = true;

      if (this.model == "forms") {
        const url =
          process.env.VUE_APP_API_URL +
          "/" +
          this.model +
          "/" +
          this.modelId +
          "/notes" +
          "/" +
          this.$route.params.id;
        this.$axios.get(url).then(({ data }) => {
          this.notes = data;
          this.busy = false;
        });
      } else {
        const url =
          process.env.VUE_APP_API_URL +
          "/" +
          this.model +
          "/" +
          this.modelId +
          "/notes";
        this.$axios.get(url).then(({ data }) => {
          this.notes = data;
          this.busy = false;
        });
      }

    },
    addModelNotes() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/" +
            this.model +
            "/" +
            this.modelId +
            "/notes",
          {
            note: this.note,
            client_id: this.$route.params.id
          }
        )
        .then(({ data }) => {
          this.note = "";
          this.$EventBus.$emit("alert", data);
          this.fetchModelNotes();
        });
    }
  },
  created() {
    this.fetchModelNotes();
  },
  mounted() {
    var modal = this.$refs.notesModal;
    console.log(modal);

    modal.addEventListener("show.bs.modal", () => {
      this.fetchModelNotes();
    });

    modal.addEventListener("hide.bs.modal", () => {
      this.$emit("close");
    });
  },
  filters: {
    formatModelName(name) {
      if (name == "enquiries") {
        return "enquiry";
      }

      if (name == "bookings/room-bookings") {
        return "room booking";
      }
    },
    formatDate(date) {
      return moment(date).format("LLL");
    }
  },
  components: { Loading }
};
</script>

<style>
</style>