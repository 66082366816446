<template>
  <div>
    <div class="row">
      <div class="col my-auto">
        <h5 class="mb-0">Intake Forms</h5>
      </div>
      <div class="col-auto ms-auto my-auto" v-if="!showAdd">
        <button class="btn btn-outline-success" @click="showAdd = !showAdd">
          <i class="far fa-plus me-2"></i>Add New
        </button>
      </div>
      <div class="col-auto ms-auto my-auto" v-else>
        <button class="btn btn-outline-success" @click="showAdd = !showAdd">
          <i class="far fa-times me-2"></i>Close
        </button>
      </div>
    </div>
    <hr class="mb-4" />
    <add-intake-form v-if="showAdd"></add-intake-form>
    <intake-forms
      v-if="!showAdd && !viewingIntake"
      @selected="viewIntake"
    ></intake-forms>
    <view-intake-form
      :intake="viewingIntake"
      v-if="viewingIntake && !showAdd"
      @close="viewingIntake = null"
    ></view-intake-form>
  </div>
</template>

<script>
import AddIntakeForm from "./intake-form-partials/AddIntakeForm";
import IntakeForms from "./intake-form-partials/IntakeForms";
import ViewIntakeForm from "./intake-form-partials/ViewIntakeForm";

export default {
  data() {
    return {
      showAdd: false,
      viewingIntake: null,
    };
  },
  watch: {
    showAdd: {
      handler(val, old) {
        if (val == true) {
          this.viewingIntake = null;
        }
      },
    },
  },
  methods: {
    viewIntake(intake) {
      this.viewingIntake = intake;
    },
  },
  mounted() {
    this.$EventBus.$on("client-intake-forms-added", () => {
      this.showAdd = false;
    });
  },
  components: {
    AddIntakeForm,
    IntakeForms,
    ViewIntakeForm,
  },
};
</script>

<style>
</style>