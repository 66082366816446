<template>
  <div class="pt-3">
    <div class="row">
      <div class="col my-auto">
        <h5 class="mb-0">Treatment Bookings</h5>
      </div>
      <div class="col-auto ms-auto my-auto" v-if="!showAdd">
        <button class="btn btn-outline-success" @click="showAdd = !showAdd">
          <i class="far fa-plus me-2"></i>Add New Booking
        </button>
      </div>
      <div class="col-auto ms-auto my-auto" v-else>
        <button class="btn btn-outline-success" @click="showAdd = !showAdd">
          <i class="far fa-times me-2"></i>Close
        </button>
      </div>
    </div>
    <hr class="mb-4" />

    <treatments
      v-if="!showAdd && !viewingTreatment"
      :viewing="viewing"
      @selected="viewTreatment"
    ></treatments>
    <view-treatment
      :booking="viewingTreatment"
      v-if="viewingTreatment && !showAdd"
      @close="closeTreatment"
    ></view-treatment>

    <add-treatment v-if="showAdd" @close="showAdd = false"></add-treatment>
  </div>
</template>

<script>
import Treatments from "./treatment-partials/Treatments";
import ViewTreatment from "./treatment-partials/ViewTreatment";
import AddTreatment from "./treatment-partials/AddTreatment";

export default {
  props: ["viewing"],
  data() {
    return {
      showAdd: false,
      viewingTreatment: null,
    };
  },
  watch: {
    showAdd: {
      handler(val, old) {
        if (val == true) {
          this.viewingTreatment = null;
        }
      },
    },
  },
  methods: {
    closeTreatment() {
      this.viewingTreatment = null;
      this.$router.replace({
        path: this.$route.currentPath,
        query: {},
        hash: "treatments",
      });
    },
    viewTreatment(treatment) {
      this.viewingTreatment = treatment;
    },
  },
  components: {
    Treatments,
    ViewTreatment,
    AddTreatment,
  },
};
</script>

<style>
</style>