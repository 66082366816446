<template>
  <div class="pt-3">
    <div class="row">
      <div class="col my-auto">
        <h5 class="mb-0">Handover Forms</h5>
      </div>
      <div class="col-auto ms-auto my-auto" v-if="!showAdd">
        <!-- <button class="btn btn-outline-success" @click="showAdd = !showAdd">
          <i class="far fa-plus me-2"></i>Add New
        </button> -->
      </div>
      <div class="col-auto ms-auto my-auto" v-else>
        <button class="btn btn-outline-success" @click="showAdd = !showAdd">
          <i class="far fa-times me-2"></i>Close
        </button>
      </div>
    </div>
    <hr class="mb-4" />
    <add-handover v-if="showAdd"></add-handover>
    <handover-forms
      v-if="!showAdd && !viewingHandover"
      @selected="viewHandover"
    ></handover-forms>
    <view-handover-form
      :handover="viewingHandover"
      v-if="viewingHandover && !showAdd"
      @close="viewingHandover = null"
    ></view-handover-form>
  </div>
</template>

<script>
import AddHandoverForm from "./handover-partials/AddHandoverForm";
import HandoverForms from "./handover-partials/HandoverForms";
import ViewHandoverForm from "./handover-partials/ViewHandoverForm";

export default {
  data() {
    return {
      showAdd: false,
      viewingHandover: null
    };
  },
  watch: {
    showAdd: {
      handler(val, old) {
        if (val == true) {
          this.viewingHandover = null;
        }
      }
    }
  },
  methods: {
    viewHandover(handover) {
      this.viewingHandover = handover;
    }
  },
  mounted() {
    this.$EventBus.$on("client-handovers-added", () => {
      this.showAdd = false;
    });
  },
  components: {
    AddHandoverForm,
    HandoverForms,
    ViewHandoverForm
  }
};
</script>

<style>
</style>