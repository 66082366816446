<template>
  <div class="bg-white pt-3">
    <div class="position-sticky top-0 w-100">
      <div class="row d-flex justify-content-between mb-4">
        <div class="col my-auto">
          <h5 class="mb-0">Notes</h5>
        </div>

        <div class="col-md-4">
          <select
            v-model="model"
            class="form-control"
          >
            <option value="">All notes</option>
            <option value="client">Client</option>
            <option value="client_form">Client form</option>
            <option value="room_booking">Room booking</option>
          </select>
        </div>
      </div>
    </div>
    <div class="card border-0 shadow-none">
      <div class="card-body">
        <div v-if="!editMode">
          <div class="row mb-4" v-for="n in notes" :key="n.id">
            <div class="col-md-8 mx-auto">
              <div class="alert alert-dark mb-2">
                <p>{{ n.content }}</p>
              </div>
              <div class="row">
                <div class="col-auto d-flex align-items-center ms-auto">
                  <span
                    class="badge rounded-pill bg-success text-capitalize me-3"
                    >{{ n.model | statusFilter }}</span
                  >
                  <small
                    class="d-flex align-items-center"
                    >{{ n.user ? n.user.name : "Unknown" }} /
                    {{ n.created_at | formatDate }}
                    <i v-if="n.created_at != n.updated_at" class="ms-2">Edited</i>

                    <button
                      v-if="user.id == n.user_id"
                      @click="editNote(n)"
                      class="btn btn-sm text-success ms-2"
                    >
                      <i class="far fa-edit"></i>
                    </button>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-8 mx-auto">
            <textarea v-model="note" rows="4" class="form-control"></textarea>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-8 mx-auto d-flex justify-content-end">
            <button v-if="!editMode" class="btn btn-sm btn-success" @click="addNote">
              <i class="far fa-check me-2"></i>Add note
            </button>
            <button v-else class="btn btn-sm btn-success" @click="saveNote">
              <i class="far fa-check me-2"></i>Save note
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      model: "",
      editMode: false,
      editId: null,
      notes: [],
      note: ""
    };
  },
  watch: {
    model() {
      this.fetchNotes();
    }
  },
  methods: {
    fetchNotes() {
      let url = process.env.VUE_APP_API_URL + "/clients/" + this.$route.params.id + "/notes";

      if (this.model) {
        url = url + "?model=" + this.model;
      }

      this.$axios
        .get(url)
        .then(({ data }) => {
          this.notes = data;
          this.editMode = false;
          this.editId = null;
        });
    },
    addNote() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.$route.params.id +
            "/notes",
          { content: this.note }
        )
        .then(({ data }) => {
          this.note = "";
          this.$EventBus.$emit("alert", { message: data.message });
          this.fetchNotes();
        });
    },
    editNote(note) {
      this.editMode = true;
      this.editId = note.id;
      this.note = note.content;
    },
    saveNote() {
      if (!this.editMode || !this.editId) {
        this.editMode = false;
        this.editId = null;

        return;
      }

      this.$axios
        .put(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.$route.params.id +
            "/notes",
          { id: this.editId, content: this.note }
        )
        .then(({ data }) => {
          this.note = "";
          this.$EventBus.$emit("alert", { message: data.message });
          this.fetchNotes();
        });
    }
  },
  mounted() {
    this.fetchNotes();
  },
  computed: {
    user() {
      return this.$store.user;
    },
  },
  filters: {
    statusFilter(string) {
      return string.replace("_", " ");
    },
    formatDate(date) {
      return moment(date).format("LLL");
    }
  }
};
</script>

<style></style>
