<template>
  <div>
    <div class="row mb-4">
      <div class="col">
        <h5 class="fw-bold" v-if="form.booking_id">
          Attached to Booking #{{ form.booking_id }}
        </h5>

        <h5 class="fw-bold" v-else>Not Attached to Booking</h5>
      </div>
      <div class="col-auto ms-auto">
        <button class="btn btn-sm btn-outline-success" @click="openNotes">
          <i class="far fa-notes-medical me-2"></i>Notes
        </button>
      </div>
      <div class="col-auto ms-auto">
        <button class="btn btn-sm btn-outline-success" @click="close">
          <i class="far fa-times"></i>
        </button>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-4">
        <div class="card border-0 shadow-none bg-light">
          <div class="card-body">
            <p>Update HQ status</p>
            <select
              v-model="form.status"
              @change="updateApproval"
              class="form-control"
            >
              <option value="approved">Approved</option>
              <option value="approved_with_food">Approved with food</option>
              <option value="fasting">Fasting</option>
              <option value="declined">Declined</option>
              <option v-if="$can('manage client data')" value="in_progress">In progress</option>
              <option value="awaiting_approval" disabled>
                Awaiting Approval
              </option>
              <option value="incomplete" disabled>Incomplete</option>
              <option value="complete" disabled>Complete</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <form-response :form-response="form"></form-response>

    <div class="row">
      <div class="col">
        <div class="card border-0 shadow-none bg-light">
          <div class="card-body">
            <textarea
              rows="5"
              class="form-control"
              v-model="handoverForm.note"
            ></textarea>

            <button class="btn btn-primary mt-3 float-end" @click="addHandover">
              <i class="far fa-check me-2"></i>Add Handover
            </button>
          </div>
        </div>
      </div>
    </div>
    <notes-modal
      v-show="showNotes"
      :model="'forms'"
      :model-id="form.id"
      @close="closeNotes"
    ></notes-modal>
  </div>
</template>

<script>
import FormResponse from "./FormResponse";
import NotesModal from "../notes/NotesModal";

export default {
  props: ["form"],
  data() {
    return {
      showNotes: false,
      handoverForm: {
        note: "",
        user_id: this.$store.user.id,
        booking_id: this.form.retreat_booking_id,
        client_id: this.$route.params.id
      }
    };
  },
  methods: {
    openNotes() {
      this.showNotes = true;
      var notesModal = new this.$bootstrap.Modal(
        document.getElementById("notesViewModal")
      );
      notesModal.show();
    },
    closeNotes() {
      this.showNotes = false;
    },
    close() {
      this.$emit("close");
    },
    updateApproval() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/forms/" + this.form.id, this.form)
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        });
    },
    addHandover() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.$route.params.id +
            "/handovers",
          this.handoverForm
        )
        .then(({ data }) => {
          this.handoverForm.note = "";
          this.$EventBus.$emit("alert", data);
        });
    }
  },
  components: {
    FormResponse,
    NotesModal
  }
};
</script>

<style></style>
