<template>
  <div class="container">
    <table class="table" v-if="!busy">
      <thead>
        <tr>
          <th scope="col" style="width: 30%">#</th>
          <th scope="col">Retreat</th>
          <th scope="col">Price</th>
          <th scope="col">Credits</th>
          <th scope="col">Payment Status</th>
          <th scope="col">Created</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="i in treatments" :key="i.id" class="cursor-pointer">
          <td @click="selectTreatment(i)">{{ i.id }}</td>
          <td @click="selectTreatment(i)" class="text-capitalize">
            {{ i.booking.retreat }}
          </td>
          <td @click="selectTreatment(i)">
            <span v-if="i.type == 'standard'">
              {{ i.booking.retreat == "uk" ? "£" : "€" }}{{ i.total.toFixed(2) }}
            </span>
            <span v-else>-</span>
          </td>
          <td @click="selectTreatment(i)">
            <span v-if="i.type == 'bundle'">
              {{ i.credits }}
            </span>
            <span v-else>-</span>
          </td>
          <td @click="selectTreatment(i)" class="text-capitalize">
            {{ i.status | formatStatus }}
          </td>
          <td @click="selectTreatment(i)">
            {{ i.created_at | formatDate }}
          </td>
        </tr>
      </tbody>
    </table>
    <loading v-else></loading>
  </div>
</template>

<script>
import Loading from "../../../../components/loaders/Loading";

export default {
  props: ["viewing"],
  data() {
    return {
      treatments: [],
      busy: false
    };
  },
  methods: {
    selectTreatment(treatment) {
      this.$emit("selected", treatment);
      if (this.$route.query.treatment != treatment.id) {
        this.$router.replace({
          path: this.$route.currentPath,
          query: {
            treatment: treatment.id
          },
          hash: "treatments"
        });
      }
    },
    deleteTreatment(treatment) {
      if (confirm("Are you sure you wish to delete this treatment form?")) {
        this.$axios
          .delete(
            process.env.VUE_APP_API_URL + "/treatment-forms/" + treatment.id
          )
          .then(({ data }) => {
            this.fetchTreatments();
          });
      }
    },
    fetchTreatments() {
      this.busy = true;
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.$route.params.id +
            "/treatment-bookings"
        )
        .then(({ data }) => {
          this.treatments = data;
          this.checkRequestedTreatment();
          this.busy = false;
        });
    },
    checkRequestedTreatment() {
      if (this.$route.query.treatment) {
        var foundTreatment = this.treatments.find(
          e => e.id == this.$route.query.treatment
        );

        if (foundTreatment) {
          this.selectTreatment(foundTreatment);
        }
      }
    }
  },
  mounted() {
    this.$EventBus.$on("client-treatment-added", this.fetchTreatments());
    this.fetchTreatments();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("L");
    },
    formatCallBackDate(date) {
      if (date) {
        return moment(date).format("LLL");
      }
      return "Not Set";
    },
    shortenPhrase(string) {
      if (string.length > 30) {
        return string.substring(0, 30) + "...";
      }
      return string;
    },
    formatStatus(status) {
      return status.replace(/_/g, " ");
    }
  },
  components: {
    Loading
  }
};
</script>

<style>
</style>