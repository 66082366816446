<template>
  <div class="pt-3">
    <div class="row">
      <div class="col my-auto">
        <h5 class="mb-0">Forms</h5>
      </div>
      <div class="col-auto ms-auto my-auto" v-if="!showAdd">
        <!-- <button class="btn btn-outline-success" @click="showAdd = !showAdd">
          <i class="far fa-plus me-2"></i>Add New
        </button> -->
      </div>
      <div class="col-auto ms-auto my-auto" v-else>
        <button class="btn btn-outline-success" @click="showAdd = !showAdd">
          <i class="far fa-times me-2"></i>Close
        </button>
      </div>
    </div>
    <hr class="mb-4" />

    <forms v-if="!showAdd && !viewingForm" @selected="viewForm"></forms>
    <view-form
      :form="viewingForm"
      v-if="viewingForm && !showAdd"
      @close="viewingForm = null"
    ></view-form>
  </div>
</template>

<script>
import Forms from "./form-partials/Forms";
import ViewForm from "./form-partials/ViewForm";

export default {
  data() {
    return {
      showAdd: false,
      viewingForm: null,
    };
  },
  watch: {
    showAdd: {
      handler(val, old) {
        if (val == true) {
          this.viewingForm = null;
        }
      },
    },
  },
  methods: {
    viewForm(form) {
      this.viewingForm = form;
    },
  },
  mounted() {},
  components: {
    Forms,
    ViewForm,
  },
};
</script>

<style></style>
