<!--
      Takes a UTC v-model and displays it in flatpickr as local time
      Returns as a UTC date formated YYYY-MM-DD HH:mm
      Data persists as UTC but displays in local time
-->
<template>
  <div>
    <flat-pickr
      :value="localValue"
      v-on:input="updateDate($event)"
      class="form-control bg-white"
      :placeholder="'Set a date and time'"
      :config="dateConfig"
      name="date"
      :required="req"
      :disabled="disabled"
    >
    </flat-pickr>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
export default {
  props: ["value", "req", "disabled", "preventClear"],
  data() {
    return {
      updatedDate: null,
    };
  },
  methods: {
    updateDate(date) {
      this.updatedDate = moment(date).utc().format("YYYY-MM-DD HH:mm");
      this.$emit("input", this.updatedDate);
      this.$emit("change", this.updatedDate);
    },
  },
  computed: {
    localValue() {
      return this.value ? moment.utc(this.value).local().toISOString() : null;
    },

    dateConfig() {
      return {
        altFormat: "J F Y H:i",
        altInput: true,
        dateFormat: "Y-m-d H:i",
        enableTime: true,
        allowInput: true,
        readOnly: false,
        minDate: "today",
        plugins: !this.preventClear
          ? [
              ShortcutButtonsPlugin({
                button: {
                  class: "btn form-control",
                  label: "Clear",
                },
                onClick: (index, fp) => {
                  fp.clear();
                  fp.close();
                },
              }),
            ]
          : [],
      };
    },
  },
  components: {
    flatPickr,
  },
};
</script>

<style>
</style>