<template>
  <div>
    <form @submit.prevent="addIntakeForm">
      <div class="row mb-4">
        <div class="col-6">
          <p>Juicing</p>
          <select v-model="form.juice_type" class="form-control">
            <option value="" disabled selected>Select option</option>
            <option value="green">Green</option>
            <option value="veg">Vegetables</option>
            <option value="fruit">Fruit</option>
            <option value="none">None</option>
          </select>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <textarea
            v-model="form.allergies"
            rows="2"
            class="form-control"
            placeholder="Allergies..."
          ></textarea>
        </div>

        <div class="col">
          <textarea
            v-model="form.intolerances"
            rows="2"
            class="form-control"
            placeholder="Intolerances..."
          ></textarea>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col">
          <textarea
            v-model="form.extra_info"
            rows="2"
            class="form-control"
            placeholder="Supplements..."
          ></textarea>
        </div>

        <div class="col">
          <textarea
            v-model="form.critical_info"
            rows="2"
            class="form-control"
            placeholder="Critical Information..."
          ></textarea>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col">
          <textarea
            v-model="form.management_notes"
            rows="2"
            class="form-control"
            placeholder="Management notes..."
          ></textarea>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col">
          <textarea
            v-model="form.kitchen_notes"
            rows="2"
            class="form-control"
            placeholder="Kitchen notes..."
          ></textarea>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-auto ms-auto">
          <button class="btn btn-success">
            <i class="far fa-check me-2"></i>Add In-take Form
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p class="alert alert-info">
            The above inputs are shared with the relevant teams automatically,
            kitchen staff may view the information in more detail from the
            Kitchen Board
          </p>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        client_id: this.$route.params.id,
        juicing_fasting: "",
        juice_type: "",
        allergies: "",
        intolerances: "",
        extra_info: "",
        critical_info: "",
        management_notes: "",
        kitchen_notes: "",
      },
    };
  },
  methods: {
    addIntakeForm() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/intake-forms", this.form)
        .then(({ data }) => {
          console.log(data);
          this.$EventBus.$emit("client-intake-forms-added");
        });
    },
  },
};
</script>

<style>
</style>