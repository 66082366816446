<template>
  <div>
    <div class="card border-0 bg-light shadow-none">
      <div class="card-body">
        <div class="row mb-3">
          <div class="col my-auto">
            <p class="mb-0 fw-bold">From: {{ email.sender }}</p>
          </div>
          <div class="col-auto my-auto">
            <button
              class="btn btn-sm btn-success me-3"
              v-if="email.client"
              @click="$router.push('/clients/' + email.client.id)"
            >
              <i class="far fa-user me-2"></i>View Profile
            </button>
            <button class="btn btn-sm btn-success" @click="reply">
              <i class="far fa-reply me-2"></i>Reply
            </button>
          </div>
          <div class="col-auto my-auto ms-auto">
            <p class="mb-0">Received {{ email.created_at | formatDate }}</p>
          </div>

          <div class="col-auto">
            <button class="btn btn-sm btn-outline-success" @click="close">
              <i class="far fa-times"></i>
            </button>
          </div>
        </div>
        <div class="card border-0 shadow-none mw-100">
          <div class="card-body">
            <h5 class="fw-bold">{{ email.subject }}</h5>

            <div class="email-view-body" v-html="email.mail_body"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["email"],
  methods: {
    reply() {
      this.$emit("reply", this.email);
    },
    close() {
      this.$emit("close");
    },
  },
  filters: {
    formatDate(date) {
      return moment.utc(date).format("LLL");
    },
  },
};
</script>

<style>
.email-view-body {
  max-width: 100%;
}
.email-view-body img {
  max-width: 100%;
}
</style>
