<template>
  <div>
    <div class="client-response-body">
      <div class="row">
        <div class="col">
          <div v-if="formResponse">
            <div class="row">
              <div class="col my-auto">
                <h5 class="mb-2">{{ formResponse.title }}</h5>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col">
                <div class="accordion" id="sectionsAccordion">
                  <div
                    v-for="section in formResponse.sections"
                    :key="section.id"
                    class="shadow"
                  >
                    <div class="card shadow-none">
                      <div
                        class="card-header"
                        :id="'heading' + section.id"
                      >
                        <div
                          class="h5 fw-bold w-100 text-left mb-0"
                          :data-target="'#collapse' + section.id"
                          aria-expanded="true"
                          :aria-controls="'collapse' + section.id"
                        >
                          <i class="fad fa-caret-down me-2"></i>
                          {{ section.title }}
                          <span
                            class="float-right font-weight-normal"
                            v-if="section.scored"
                          >
                            Score: <b>{{ section.score }}</b>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div
                        :id="'collapse' + section.id"
                        class="collapse show"
                        :aria-labelledby="'heading' + section.id"
                        data-parent="#sectionsAccordion"
                      >
                        <div class="card-body" :ref="'body-' + section.id">
                          <form
                            :id="'form-render-' + section.id"
                            :class="'form-render-' + section.id"
                          ></form>

                          <div v-if="section.scored">
                            <h5 class="fw-bold">
                              Section Score Total: {{ section.score }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="formResponseHasScoredSections(formResponse)"
                    style="border-radius: 0"
                  >
                    <div
                      class="card shadow-none w-100"
                      style="border-radius: 0"
                    >
                      <div class="card-header pl-1 pr-3" :id="'heading-total'">
                        <h4 class="ms-3">
                          Scored Grand Total:
                          {{ calculateScoredTotal(formResponse) }}
                        </h4>
                      </div>
                    </div>
                    <div class="card shadow-none"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        class="btn btn-sm btn-outline-primary"
        @click="downloadPdf(formResponse)"
      >
        <i class="fad fa-file-pdf me-1"></i>
        Download PDF
      </button>
      <button
        type="button"
        class="btn btn-secondary btn-sm ms-auto"
        data-dismiss="modal"
      >
        Close
      </button>
    </div>
  </div>
</template>

<script>
require("jquery-ui-bundle");
require("formBuilder/dist/form-render.min.js");

export default {
  props: ["formResponse"],
  data() {
    return {};
  },
  methods: {
    openClientRequest() {
      for (let i = 0; i < this.formResponse.sections.length; i++) {
        const section = this.formResponse.sections[i];

        setTimeout(() => {
          var formRenderOptions = {
            dataType: "json",
            formData: section.client_response,
            layoutTemplates: {
              default: function(field, label, help, data) {
                if (data.type === "radio-group") {
                  const selectedVal = data.userData ? data.userData[0] : "";

                  help = "<p>";
                  data.values.forEach(val => {
                    if (selectedVal == val.value) {
                      help +=
                        "<i class='fad fa-check-circle text-primary me-1'></i>" +
                        "<u class='me-3'>" +
                        val.label +
                        "</u><br />";
                    } else {
                      help +=
                        "<i class='fad fa-circle text-light me-1'></i>" +
                        "<span class='me-3'>" +
                        val.label +
                        "</span><br />";
                    }
                  });
                  help += "</p>";

                  $(field).addClass("d-none");
                }

                if (data.type === "checkbox-group") {
                  help = "<p>";
                  data.values.forEach(val => {
                    if (data.userData && data.userData.includes(val.value)) {
                      help +=
                        "<i class='fad fa-check-circle text-primary me-1'></i>" +
                        "<u class='me-3'>" +
                        val.label +
                        "</u><br />";

                        // For some reason the "Other" checkbox isn't saved, so we just
                        // empty out the userData until only that value can be left
                        data.userData.splice(data.userData.indexOf(val.value), 1);

                    } else {
                      help +=
                        "<i class='fad fa-circle text-light me-1'></i>" +
                        "<span class='me-3'>" +
                        val.label +
                        "</span><br />";
                    }
                  });

                  // Display any left over ("Other") user data
                  if (data.userData.length) {
                    help +=
                      "<i class='fad fa-check-circle text-primary me-1'></i>" +
                      "<u class='me-3'>" +
                      "Other: " + data.userData[0]
                      "</u><br />";
                  }

                  help += "</p>";

                  $(field).addClass("d-none");
                }

                if (field.type === "textarea" || field.type === "text") {
                  help =
                    "<p><br /><div class='rounded ms-1 p-3 d-block' " +
                    "style='border: 1px solid #B3BEBF'>" +
                    data.userData
                      ? data.userData
                      : "" + "</div></p>";
                  $(field).addClass("d-none");
                }

                if (field.type === "select-one") {
                  const value = data.userData ? data.userData[0] : "";
                  help =
                    "<div class='rounded px-3 py-2' " +
                    "style='border: 1px solid #B3BEBF'>" +
                    value +
                    "</div>";
                  $(field).addClass("d-none");
                }
                return $("<div/>").append(label, field, help);
              }
            }
          };

          $(".form-render-" + section.id).formRender(formRenderOptions);

          $(".client-response-body")
            .find("input, textarea, select")
            .attr("disabled", "disabled");

          $("#form-responses").modal("show");
        }, 200);
      }
    },
    downloadPdf(response) {
      this.$nextTick(() => {
        const element = window.document.getElementById("form-print-container");

        let stylesHtml = "";
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style')
        ]) {
          stylesHtml += node.outerHTML;
        }

        const win = window.open(
          "",
          "_blank",
          "toolbar=0,scrollbars=0,status=0",
          true
        );
        win.document.write(`
        <html>
          <head>
            <meta name="viewport" content="width=device-width, initial-scale=1">
            ${stylesHtml}
            <title>Document Preview</title>
          </head>
          <body>
            ${element.innerHTML}
          </body>
        </html>
      `);
        setTimeout(() => {
          win.document.close();
          win.focus();
          win.print();
          win.close();
          cb();
        }, 1000);
      });
    },
    formResponseHasScoredSections(response) {
      if (response.sections) {
        return (
          response.sections.filter(item => {
            return item.scored;
          }).length > 0
        );
      } else {
        return false;
      }
    },
    calculateScoredTotal(response) {
      if (response.sections) {
        return response.sections.reduce((a, b) => +a + +b.score, 0);
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.openClientRequest();
  }
};
</script>

<style lang="scss" scoped>
.table-hover .rows {
  cursor: pointer;
}

.formbuilder-textarea-label {
  display: block !important;
}

@media print {
  #form-responses {
    display: block;
    background-color: white;
    opacity: 1 !important;
  }

  .collapse {
    display: block !important;
    height: auto !important;
  }

  .modal {
    height: 100% !important;
    position: relative !important;
    top: unset !important;
    left: unset !important;
    width: 100% !important;
  }

  .card-body {
    padding: 0px 40px;
  }

  h2 > button {
    font-size: 28px;
  }

  .modal-backdrop {
    display: none;
  }
}
</style>
