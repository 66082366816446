<template>
  <div>
    <div class="row">
      <div class="col">
        <h5 class="fw-bold">
          Retreat Booking #{{ retreatBooking.id }} has
          {{ retreatBooking.room_bookings.length }} room{{
            retreatBooking.room_bookings.length === 0 ? "" : ""
          }}
          booked for {{ retreatBooking.total_nights }} nights in
          {{ retreatBooking.retreat }}
        </h5>
      </div>
      <div class="col-auto ms-auto">
        <button class="btn btn-sm btn-outline-success" @click="close">
          <i class="far fa-times"></i>
        </button>
      </div>
    </div>

    <div class="mb-4">
      <small class="d-block mb-2"
        >Booking has {{ retreatBooking.clients.length }} clients</small
      >
      <div class="row">
        <div class="col">
          <router-link
            class="btn btn-sm btn-outline-success me-2"
            v-for="c in retreatBooking.clients"
            :key="c.id"
            :to="
              '/clients/' +
              c.id +
              '?booking=' +
              retreatBooking.id +
              '#retreat-bookings'
            "
            >{{ c.name }}
            {{
              c.id == retreatBooking.lead_client_id ? "(lead)" : ""
            }}</router-link
          >
        </div>
      </div>
    </div>

    <room-booking-details
      v-for="rb in retreatBooking.room_bookings"
      :key="rb.id"
      :b="retreatBooking"
      :rb="rb"
      @close="close"
    ></room-booking-details>
  </div>
</template>

<script>
import RoomBookingDetails from "./view-partials/RoomBookingDetails";

export default {
  props: ["retreatBooking"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatStatus(status) {
      return status.replace(/_/g, " ");
    },
  },
  components: {
    RoomBookingDetails,
  },
};
</script>

<style></style>
